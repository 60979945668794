import * as React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Divider,
  Grid,
  Stack,
  IconButton,
  Hidden,
  Badge,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ProfileMenu from "./ProfileMenu";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CustomPopover from "components/common/customPopover";
import SearchIcon from "@mui/icons-material/Search";
import SearchBar from "./SearchBar";
import useResponsive from "hooks/useResponsive";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useAuth } from "hooks/useAuth";
import Wallet from "../common/Wallet";
import ThemeModeSwitch from "./ThemeModeSwitch";
import AppTourPopover from "./AppTourPopover";

export default function Header(props) {
  const { setDrawer } = props;
  const { user } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  return (
    <>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        style={{ zIndex: 100 }}
      >
        <Toolbar>
          <Grid
            container
            spacing={{ md: 2, xs: 0 }}
            alignItems="center"
            wrap="noWrap"
          >
            <Hidden smUp>
              <Grid item xs="auto">
                <IconButton onClick={() => setDrawer(true)} sx={{ ml: -1 }}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs={5} sm={4}>
              <Logo
                onClick={() =>
                  navigate(
                    `${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=SENT`,
                  )
                }
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  maxWidth: "134px",
                  maxHeight: "32px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Hidden mdDown>
              <Grid item xs={0} sm={4}>
                <SearchBar />
              </Grid>
            </Hidden>
            <Grid item xs container justifyContent="flex-end">
              <Stack
                direction="row"
                ml={{ xs: 1 }}
                spacing={{ md: 1, xs: 0 }}
                alignItems="center"
                mr={-1}
              >
                {isMobile && (
                  <CustomPopover
                    AnchorComponent={(syntax) => (
                      <IconButton {...syntax}>
                        <SearchIcon />
                      </IconButton>
                    )}
                    PopoverComponent={(syntax) => (
                      <Box m={2} {...syntax}>
                        <SearchBar autoFocus />
                      </Box>
                    )}
                  />
                )}
                <Hidden mdDown>
                  <AppTourPopover />
                </Hidden>
                <ThemeModeSwitch />
                {user?.plan !== "FREE" && (
                  <CustomPopover
                    AnchorComponent={(syntax) => (
                      <IconButton {...syntax}>
                        <Badge
                          badgeContent={(user?.credits || 0).toString()}
                          max={99999}
                          color={
                            !user?.credits || user.credits <= 0
                              ? "error"
                              : user.credits > 100
                              ? "success"
                              : "warning"
                          }
                        >
                          <AccountBalanceWalletOutlinedIcon />
                        </Badge>
                      </IconButton>
                    )}
                    PopoverComponent={(syntax) => (
                      <Box p={2}>
                        <Wallet {...syntax} renderTransactionHistoryAnchor />{" "}
                      </Box>
                    )}
                  />
                )}
                <IconButton
                  onClick={() =>
                    navigate(
                      `${process.env.REACT_APP_BASE_ROUTE}/user/settings`,
                    )
                  }
                >
                  <SettingsIcon />
                </IconButton>
                <ProfileMenu />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Divider />
    </>
  );
}
